<template>
  <div class="search">
    <div class="header">
      {{ this.$t("product.search.result") }}
      <span class="number">{{ this.total_count }}</span
      >{{ this.$t("product.search.item") }}
    </div>
    <div class="body">
      <ul class="product">
        <li class="no_data" v-if="this.product_data.length <= 0">
          {{ this.$t("product.list.no_data") }}
        </li>
        <li v-for="(value, index) in this.product_data" :key="index">
          <div
            class="image"
            :style="{
              backgroundImage:
                'url(' +
                this.get_image_url_from_data(
                  value.product_id,
                  value.product_images
                ) +
                ')',
            }"
            @click="this.$router.push('/product/detail/' + value.product_id)"
          ></div>
          <div class="desc">
            <div class="seller">{{ value.seller_name }}</div>
            <div class="name">
              <router-link
                :to="{
                  name: 'detail',
                  params: { product_id: value.product_id },
                }"
                >{{ value.product_name }}</router-link
              >
            </div>
            <div class="price">
              <span
                class="percent"
                v-show="value.retail_price - value.sale_price > 0"
                >{{
                  this.common.number_format(
                    ((value.retail_price - value.sale_price) /
                      value.retail_price) *
                      100.0,
                    1
                  )
                }}%</span
              >
              <span class="sale">{{
                this.common.currency_format(value.sale_price, value.unit)
              }}</span>
              <span
                class="avatar"
                v-if="value.item_id != null && value.item_id > 0"
              ></span>
            </div>
          </div>
        </li>
      </ul>

      <div class="more">
        <button
          class="button middle"
          @click="this.load_product_data"
          v-if="!this.data_fully_loaded"
        >
          {{ this.$t("common.load_more") }}
        </button>
      </div>
    </div>
  </div>

  <Spinner v-show="processing" />
</template>

<script>
export default {
  name: "Search",
  metaInfo() {
    return {
      title: this.$t("meta.title") + " :: " + this.$t("meta.search"),
      og: {
        title: this.$t("meta.title") + " :: " + this.$t("meta.search"),
      },
    };
  },
  data: function () {
    return {
      process_queue: [],
      product_data: [],
      data_fully_loaded: false,
      search_keyword: "",
      search_page: 1,
      total_count: 0,
    };
  },
  computed: {
    processing() {
      if (this.process_queue.length <= 0) return false;
      else return true;
    },
  },
  mounted() {
    if (
      typeof this.$route.query.keyword != "undefined" &&
      this.$route.query.keyword.trim() != ""
    ) {
      this.search_keyword = this.$route.query.keyword;
      this.load_product_data();
    }
  },
  methods: {
    get_image_url_from_data: function (product_id, str_images) {
      let arr_images = str_images.split("|");
      if (arr_images.length) {
        return (
          process.env.VUE_APP_API_URL +
          "/resource/get/product/" +
          product_id +
          "/" +
          arr_images[0]
        );
      }

      return "";
    }, //-- get_image_url_from_data

    load_product_data: function () {
      if (this.data_fully_loaded) return;

      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/product/list",
          {
            product_name: self.search_keyword,
            page: self.search_page,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          if (self.search_page <= 1) {
            self.product_data = [];
          }

          let data = response.data;

          for (let i = 0; i < data.length; i++) {
            let item = data[i];
            self.product_data.push(item);
          }

          if (data.length <= 0) {
            self.data_fully_loaded = true;
          } else {
            self.search_page++;
          }

          // 총 갯수 정보 헤더에서 가져오기
          try {
            let str_info = response.headers.wkt_extra_info;
            let extra_info = JSON.parse(str_info);
            self.total_count = parseInt(extra_info.total_count);
          } catch (e) {
            console.log(e);
          }
        })
        .catch(function (error) {
          alert(error);
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    }, //-- load_product_group_data
  },
};
</script>

<style scoped>
.search {
  display: block;
  width: 1200px;
  max-width: 100%;
  margin: 1rem auto;
  padding: 0.8rem;

  background: #fff;
}
.search > .header {
  padding: 0.8rem 0;
  font-size: 1.2rem;
  font-weight: 700;
}
.search > .header > .number {
  color: #e74356;
}
.search > .body {
  padding: 0.8rem 0;
}
.search > .body > .more {
  text-align: center;
}
.search > .body > .more > button.button {
  letter-spacing: 0;
}
</style>
